import Typography from "@mui/material/Typography";
import useStore from "@/stores/store";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { theme } from "@/theme/theme";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import QuickAction from "@/components/quickaction/QuickAction";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const FundHome = () => {
  const router = useRouter();
  const companyDetails = useStore((state) => state.user?.company);
  const userInfo = useStore((state) => state.user);
  const portfolioWorkforceAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.portfolio_analytics?.find((x) => x.name === "Workforce");
  });
  const portfolioWorkforceAnalyticsAlt = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.portfolio?.find((x) => x.name === "Workforce Insights");
  });
  const portfolioEngagementAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.portfolio_analytics?.find((x) => x.name === "Engagement");
  });
  const portfolioEngagementInsights = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.portfolio?.find((x) => x.name === "Engagement Insights");
  });
  const collectSurveys = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.collect?.find((x) => x.name.includes("Survey"));
  });
  const benchmarks = useStore((state) => {
    return state.companySettings?.find((setting) => setting.name === "DRAWER")
      ?.value?.benchmark;
  });
  const employeeOverview = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.collect?.find((x) => x.name === "Overview");
  });
  const internalAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.analytics?.find((x) => x.name === "Internal");
  });
  const boardAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.portfolio?.find((x) => x.name === "Board Insights");
  });
  const internalCompanyAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.company?.find((x) => x.name === "Workforce Insights");
  });

  const getFirstName = (name: string) => {
    return name.split(" ")[0];
  };
  return (
    <Box>
      <Box>
        <Card
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            color: "black",
            width: "100%",
            marginBottom: 5,
          }}
        >
          <CardHeader title={`Welcome, ${getFirstName(userInfo?.name)}!`} />
          <CardContent>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {`We're excited to team up with ${companyDetails?.name}.`}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {`Need help? Reach out anytime to `}
              <Link href="mailto:help@findarbor.com" color="#d87830">
                {`help@findarbor.com`}
              </Link>
              {`.`}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr", lg: "repeat(2, 1fr)" }, // Responsive grid layout
          gap: { xs: 2, sm: 4, md: 10 },
        }}
      >
        <Card
          sx={{
            marginTop: 2,
            display: "grid",
            color: "black",
            width: "100%",
            marginBottom: 5,
            minHeight: "100%",
          }}
        >
          <CardHeader
            title={`Quick Navigation`}
            sx={{ marginInlineStart: 1 }}
          />
          <CardContent
            sx={{
              display: "grid",
              rowGap: 1,
              gridTemplateRows: "1fr 1fr 1fr",
              placeItems: "start",
            }}
          >
            {collectSurveys && (
              <QuickAction
                title={`View Surveys`}
                handleClick={() => router.push(ROUTES.SURVEY_LIST)}
                disabled={false}
              />
            )}
            {employeeOverview && (
              <QuickAction
                title={`View Employee Overview Table`}
                handleClick={() => router.push(ROUTES.EMPLOYEE_OVERVIEW)}
                disabled={false}
              />
            )}
            {(internalAnalytics || internalCompanyAnalytics) && (
              <QuickAction
                title={`View Internal Analytics`}
                handleClick={() => router.push(ROUTES.INTERNAL_ANALYTICS)}
                disabled={false}
              />
            )}
            {boardAnalytics && (
              <QuickAction
                title={`View Board Analytics`}
                handleClick={() =>
                  router.push(
                    `${ROUTES.PORTFOLIO_ANALYTICS}/${boardAnalytics.type}/${boardAnalytics.instance_id}`
                  )
                }
                disabled={false}
              />
            )}
            {portfolioWorkforceAnalytics && (
              <QuickAction
                title={`View Portfolio Analytics`}
                handleClick={() =>
                  router.push(
                    `${ROUTES.PORTFOLIO_ANALYTICS}/${portfolioWorkforceAnalytics.type}/${portfolioWorkforceAnalytics.instance_id}`
                  )
                }
                disabled={false}
              />
            )}
            {portfolioWorkforceAnalyticsAlt && (
              <QuickAction
                title={`View Portfolio Analytics`}
                handleClick={() =>
                  router.push(
                    `${ROUTES.PORTFOLIO_ANALYTICS}/${portfolioWorkforceAnalyticsAlt.type}/${portfolioWorkforceAnalyticsAlt.instance_id}`
                  )
                }
                disabled={false}
              />
            )}
            {portfolioEngagementAnalytics && (
              <QuickAction
                title={`View Engagement Analytics`}
                handleClick={() =>
                  router.push(
                    `${ROUTES.PORTFOLIO_ANALYTICS}/${portfolioEngagementAnalytics.type}/${portfolioEngagementAnalytics.instance_id}`
                  )
                }
                disabled={false}
              />
            )}
            {portfolioEngagementInsights && (
              <QuickAction
                title={`View Engagement Analytics`}
                handleClick={() =>
                  router.push(
                    `${ROUTES.PORTFOLIO_ANALYTICS}/${portfolioEngagementInsights.type}/${portfolioEngagementInsights.instance_id}`
                  )
                }
                disabled={false}
              />
            )}
            {benchmarks && (
              <QuickAction
                title={`View Benchmarks`}
                handleClick={() => router.push(ROUTES.BENCHMAKRS)}
                disabled={false}
              />
            )}
          </CardContent>
        </Card>
        <Box>
          <Card
            sx={{
              display: "grid",
              marginTop: 2,
              color: "black",
              width: "100%",
              marginBottom: 5,
              minHeight: "100%",
            }}
          >
            <CardHeader title={`Helpful Links`} sx={{ marginInlineStart: 1 }} />
            <CardContent>
              <Box sx={{ display: "grid", textAlign: "start", gap: 2 }}>
                <Box>
                  <Button
                    href="https://www.findarbor.com/resources"
                    target="_blank"
                    variant="text"
                    startIcon={<OpenInNewIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    <Typography
                      variant="body1"
                      color={theme.palette.primary.main}
                    >
                      {`Resource Library`}
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    href="https://www.findarbor.com/privacy-policy"
                    target="_blank"
                    variant="text"
                    startIcon={<OpenInNewIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    <Typography
                      variant="body1"
                      color={theme.palette.primary.main}
                    >
                      {`Privacy Policy`}
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    href="https://www.findarbor.com/security"
                    target="_blank"
                    variant="text"
                    startIcon={<OpenInNewIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    <Typography
                      variant="body1"
                      color={theme.palette.primary.main}
                    >
                      {`Data Security`}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default FundHome;
