export enum QueryKeys {
  USER_DETAILS = "USER_DETAILS",
  INTEGRATION_LINK_TOKEN = "INTEGRATION_LINK_TOKEN",
  STORE_INTEGRATION_TOKEN = "STORE_INTEGRATION_TOKEN",
  HRIS_TOKEN_STATUS = "HRIS_TOKEN_STATUS",
  HRIS_DROPDOWN_DATA = "HRIS_DROPDOWN_DATA",
  ATS_TOKEN_STATUS = "ATS_TOKEN_STATUS",
  BENCHMARKS_INDUSTRY_DROPDOWN = "BENCHMARKS_INDUSTRY_DROPDOWN",
  BENCHMARKS_DEMOGRAPHIC_DROPDOWN = "BENCHMARKS_DEMOGRAPHIC_DROPDOWN",
  BENCHMARKS_GROUP_DROPDOWN = "BENCHMARKS_GROUP_DROPDOWN",
  BENCHMARKS_DATA = "BENCHMARKS_DATA",
  ANALYTICS_INTERNAL_GENDER_REPRESENTATION_DATA = "ANALYTICS_INTERNAL_GENDER_REPRESENTATION_DATA",
  ANALYTICS_INTERNAL_RACE_REPRESENTATION_DATA = "ANALYTICS_INTERNAL_RACE_REPRESENTATION_DATA",
  ANALYTICS_INTERNAL_GENDER_PAY_DATA = "ANALYTICS_INTERNAL_GENDER_PAY_DATA",
  ANALYTICS_INTERNAL_RACE_PAY_DATA = "ANALYTICS_INTERNAL_RACE_PAY_DATA",
  SURVEY_LIST = "SURVEY_LIST",
  SURVEY_EDITOR_INSTANCE_BY_ID = "SURVEY_EDITOR_INSTANCE_BY_ID",
  SURVEY_PORTFOLIO_EDITOR_INSTANCE_BY_ID = "SURVEY_PORTFOLIO_EDITOR_INSTANCE_BY_ID",
  SURVEY_INSTANCE_BY_ID = "SURVEY_INSTANCE_BY_ID",
  SURVEY_PORTFOLIO_LIST = "SURVEY_PORTFOLIO_LIST",
  SURVEYS_SENT_LIST = "SURVEYS_SENT_LIST",
  BOARD_RESPONSE_LIST = "BOARD_RESPONSE_LIST",
  SURVEY_UDPATE_RESPONSE = "SURVEY_UDPATE_RESPONSE",
  COMPANY_PORTFOLIOS = "COMPANY_PORTFOLIOS",
  PORTFOLIO_COMPANIES_WORKFORCE_STATUS_LIST = "PORTFOLIO_COMPANIES_WORKFORCE_STATUS_LIST",
  PORTFOLIO_PARTICIPATION_GRAPH_DATA = "PORTFOLIO_PARTICIPATION_GRAPH_DATA",
  PORTFOLIO_LEADERSHIP_GRAPH_DATA = "PORTFOLIO_LEADERSHIP_GRAPH_DATA",
  PORTFOLIO_DISTRIBUTION_GRAPH_DATA = "PORTFOLIO_DISTRIBUTION_GRAPH_DATA",
  PORTFOLIO_ADDITIONAL_REPRESENTATION_GRAPH_DATA = "PORTFOLIO_ADDITIONAL_REPRESENTATION_GRAPH_DATA",
  PORTFOLIO_BOARD_DEI_GRAPH_DATA = "PORTFOLIO_BOARD_DEI_GRAPH_DATA",
  PORTFOLIO_STATS_GRAPH_DATA = "PORTFOLIO_STATS_GRAPH_DATA",
  PORTFOLIO_PROMOTIONS_AND_TURNOVER_GRAPH_DATA = "PORTFOLIO_PROMOTIONS_AND_TURNOVER_GRAPH_DATA",
  PORTFOLIO_CLIMATE_POLICY_GRAPH_DATA = "PORTFOLIO_CLIMATE_POLICY_GRAPH_DATA",
  PORTFOLIO_GREENHOUSE_GAS_EMISSIONS_GRAPH_DATA = "PORTFOLIO_GREENHOUSE_GAS_EMISSIONS_GRAPH_DATA",
  PORTFOLIO_BOARDS = "PORTFOLIO_BOARDS",
  PORTFOLIO_BOARD_GRAPH_DATA = "PORTFOLIO_BOARD_GRAPH_DATA",
  PORTFOLIO_BOARD_JOINING_YEARS = "PORTFOLIO_BOARD_JOINING_YEARS",
  SENT_SURVEY_DETAILS = "SENT_SURVEY_DETAILS",
  LATEST_FUND_SURVEY_STATUS = "LATEST_FUND_SURVEY_STATUS",
  QR_FEEDBACK_DATA = "QR_FEEDBACK_DATA",
  ENGAGEMENT_SURVEY_STATUS = "ENGAGEMENT_SURVEY_STATUS",
  ENGAGEMENT_SURVEY_QUESTIONS = "ENGAGEMENT_SURVEY_QUESTIONS",
  ENGAGEMENT_SURVEY_FILTERS = "ENGAGEMENT_SURVEY_FILTERS",
  ENGAGEMENT_PORTFOLIO_EDITOR_GRAPH_DATA = "ENGAGEMENT_PORTFOLIO_EDITOR_GRAPH_DATA",
  ENGAGEMENT_FUND_RESPONSE_RATE_GRAPH_DATA = "ENGAGEMENT_FUND_RESPONSE_RATE_GRAPH_DATA",
  FUND_SHARED_RESPONSE_LIST = "FUND_SHARED_RESPONSE_LIST",
  HRIS_ADDITIONAL_REPRESENTATION_GRAPH_DATA = "HRIS_ADDITIONAL_REPRESENTATION_GRAPH_DATA",
  HRIS_EMPLOYEE_OVERVIEW_DATA = "HRIS_EMPLOYEE_OVERVIEW_DATA",
}
