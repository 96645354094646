import authenticatedClient from "../http/authenticatedClient";
import * as Sentry from "@sentry/nextjs";
const fetchUserDetails = async () => {
  try {
    const response = await authenticatedClient.get("/user");
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export default fetchUserDetails;
