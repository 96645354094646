import authenticatedClient from "../http/authenticatedClient";
import * as Sentry from "@sentry/nextjs";
const fetchLatestFundSurveyStatus = async () => {
  try {
    const response = await authenticatedClient.get(
      "/survey/latest-fund-survey-status"
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
export default fetchLatestFundSurveyStatus;
