import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface IQuickActionProps {
  title: string;
  handleClick: () => void;
  disabled?: boolean;
  tooltipContent?: React.ReactNode;
}

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: transparent;
  }
`;

const QuickAction: React.FC<IQuickActionProps> = ({
  title,
  handleClick,
  disabled = false,
  tooltipContent = null,
}) => {
  return (
    <StyledTooltip
      title={tooltipContent}
      placement="right-start"
      sx={{
        display: tooltipContent ? "block" : "none",
      }}
    >
      <Card
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: "16px",
          paddingInline: "16px",
          paddingBlock: "12px",
          border: "1px solid #00522E80",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "6fr 1fr",
            alignItems: "center",
          }}
        >
          <Box sx={{ paddingInline: "8px" }}>
            <Typography variant="body1">{title}</Typography>
          </Box>
          <Box sx={{ paddingInline: "22px" }}>
            <Button
              variant="contained"
              onClick={handleClick}
              disabled={disabled}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Card>
    </StyledTooltip>
  );
};

export default QuickAction;
