import axios from "axios";
import { firebaseAuth } from "../auth";

const authenticatedClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_FUND_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

authenticatedClient.interceptors.request.use(
  async (config) => {
    const token = await firebaseAuth.currentUser?.getIdToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default authenticatedClient;
