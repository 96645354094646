import DefaultPageLayout from "@/components/pagelayouts/DefaultPageLayout";
import { ReactElement, useEffect } from "react";
import type { NextPageWithLayout } from "@/pages/_app";
import PageWrapper from "@/components/PageWrapper";
import fetchUserDetails from "@/services/user/fetchUserDetails";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "@/constants/querykeys";
import useStore from "@/stores/store";
import FundHome from "@/components/home/FundHome";
import PortfolioHome from "@/components/home/PortfolioHome";
import { useAuth } from "@/contexts/AuthContext";

const HomePage: NextPageWithLayout = () => {
  const setUser = useStore((state) => state.setUser);
  const { user } = useAuth();
  const companyFeatureSettings = useStore(
    (state) =>
      state.companySettings?.find((setting) => setting.name === "FEATURE_FLAGS")
        ?.value
  );

  const setCompanySettings = useStore((state) => state.setCompanySettings);
  const { isPending, data: userDetails } = useQuery({
    queryKey: [QueryKeys.USER_DETAILS],
    queryFn: fetchUserDetails,
    enabled: Boolean(user),
  });

  useEffect(() => {
    setUser(userDetails?.userDetail);

    setCompanySettings(userDetails?.userDetail?.company?.CompanyAttribute);
  }, [userDetails, setUser, setCompanySettings]);

  if (isPending) {
    return <div>Loading...</div>;
  }
  return (
    <PageWrapper>
      {companyFeatureSettings?.FUND_HOME_PAGE && <FundHome />}
      {companyFeatureSettings?.PORTFOLIO_HOME_PAGE && (
        <PortfolioHome features={companyFeatureSettings} />
      )}
    </PageWrapper>
  );
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <DefaultPageLayout title={`Home`}>{page}</DefaultPageLayout>;
};

export default HomePage;
