import Typography from "@mui/material/Typography";
import useStore from "@/stores/store";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import QuickAction from "@/components/quickaction/QuickAction";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import Button from "@mui/material/Button";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { ResponderStatus } from "@/constants/surveystatus";
import { QueryKeys } from "@/constants/querykeys";
import fetchLatestFundSurveyStatus from "@/services/survey/fetchLatestFundSurveyStatus";
import { useQuery } from "@tanstack/react-query";
import { theme } from "@/theme/theme";

const ReminderComponent = ({
  fundName,
  surveyName,
  status,
  completionDate,
  surveyId,
  type,
}) => {
  const router = useRouter();
  const completedMessage = `You completed ${surveyName} on ${completionDate}.`;
  const pendingMessage = `${fundName} has invited you to complete a Survey.`;
  const completedQuickAction = `View Completed Survey`;
  const pendingQuickAction = `Complete Survey`;
  return (
    <>
      <Alert severity="info" icon={false} sx={{ width: "100%" }}>
        <AlertTitle>Reminder</AlertTitle>
        {status === ResponderStatus.COMPLETED
          ? completedMessage
          : pendingMessage}
      </Alert>
      <QuickAction
        title={
          status === ResponderStatus.COMPLETED
            ? completedQuickAction
            : pendingQuickAction
        }
        handleClick={() =>
          router.push(
            `${ROUTES.SURVEY}/${type}/${ROUTES.SURVEY_RESPONDER}/${surveyId}`
          )
        }
        disabled={false}
      />
    </>
  );
};

const OfferBox = ({ companyDetails }) => {
  return (
    <Box>
      <Card
        sx={{
          display: "grid",
          marginTop: 2,
          color: "black",
          width: "100%",
          marginBottom: 5,
          minHeight: "100%",
        }}
      >
        <CardHeader title={`About Arbor`} sx={{ marginInlineStart: 1 }} />
        <CardContent>
          <Box
            sx={{
              display: "grid",
              textAlign: "start",
              gap: 2,
              marginInlineStart: 1,
            }}
          >
            <Box>
              <Typography variant="body1">{`Want to improve engagement, hiring, and retention?`}</Typography>
            </Box>
            <Box>
              <Typography variant="body1">{`Make data-driven decisions with Arbor's people data tools.`}</Typography>
            </Box>
            <Box
              sx={{
                background: "#E0EAE6",
                borderRadius: 5,
                padding: 3,
                display: "grid",
                placeItems: "center",

                gap: 2,
              }}
            >
              <Typography
                variant="body1"
                align="center"
              >{`${companyDetails?.fundedBy?.[0]?.name}-affiliated portfolio companies receive up to 25% off Arbor's suite of people data tools.`}</Typography>
              <Box>
                <Button
                  component="a"
                  href="https://www.findarbor.com/portfolio-partner-arbor"
                  variant="contained"
                  color="primary"
                  target="_blank"
                  endIcon={<OpenInNewIcon />}
                >
                  Activate your discount
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const HelpfulLinksBox = () => {
  return (
    <Box>
      <Card
        sx={{
          display: "grid",
          marginTop: 2,
          color: "black",
          width: "100%",
          marginBottom: 5,
          minHeight: "100%",
        }}
      >
        <CardHeader title={`Helpful Links`} sx={{ marginInlineStart: 1 }} />
        <CardContent>
          <Box sx={{ display: "grid", textAlign: "start", gap: 2 }}>
            <Box>
              <Button
                href="https://www.findarbor.com/resources"
                target="_blank"
                variant="text"
                startIcon={<OpenInNewIcon />}
                sx={{ textTransform: "none" }}
              >
                <Typography variant="body1" color={theme.palette.primary.main}>
                  {`Resource Library`}
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                href="https://www.findarbor.com/privacy-policy"
                target="_blank"
                variant="text"
                startIcon={<OpenInNewIcon />}
                sx={{ textTransform: "none" }}
              >
                <Typography variant="body1" color={theme.palette.primary.main}>
                  {`Privacy Policy`}
                </Typography>
              </Button>
            </Box>

            <Box>
              <Button
                href="https://www.findarbor.com/security"
                target="_blank"
                variant="text"
                startIcon={<OpenInNewIcon />}
                sx={{ textTransform: "none" }}
              >
                <Typography variant="body1" color={theme.palette.primary.main}>
                  {`Data Security`}
                </Typography>
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
const PortfolioHome = ({ features }) => {
  const router = useRouter();
  const companyDetails = useStore((state) => state.user?.company);
  const userInfo = useStore((state) => state.user);
  const benchmarks = useStore((state) => {
    return state.companySettings?.find((setting) => setting.name === "DRAWER")
      ?.value?.benchmark;
  });
  const collectSurveys = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.collect?.find((x) => x.name.includes("Survey"));
  });
  const employeeOverview = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.collect?.find((x) => x.name === "Overview");
  });
  const internalAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.analytics?.find((x) => x.name === "Internal");
  });
  const internalCompanyAnalytics = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.company?.find((x) => x.name === "Workforce Insights");
  });
  const internalEngagementInsights = useStore((state) => {
    return state.companySettings
      ?.find((setting) => setting.name === "DRAWER")
      ?.value?.company?.find((x) => x.name === "Engagement Insights");
  });
  const { data: latestSurveyStatusData } = useQuery({
    queryKey: [QueryKeys.LATEST_FUND_SURVEY_STATUS],
    queryFn: async () => {
      const { data } = await fetchLatestFundSurveyStatus();
      return data;
    },
    enabled: Boolean(userInfo?.company?.id),
    retry: false,
  });
  const getFirstName = (name: string) => {
    return name.split(" ")[0];
  };
  return (
    <Box>
      <Card
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          color: "black",
          width: "100%",
          marginBottom: 5,
        }}
      >
        <CardHeader title={`Welcome, ${getFirstName(userInfo?.name)}!`} />
        <CardContent>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {`It's great to have you here.`}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {`Need help? Reach out anytime to `}
            <Link href="mailto:help@findarbor.com" color="#d87830">
              {`help@findarbor.com`}
            </Link>
            {`.`}
          </Typography>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr", lg: "repeat(2, 1fr)" }, // Responsive grid layout
          gap: { xs: 2, sm: 4, md: 10 }, // Responsive gap
        }}
      >
        <Card
          sx={{
            marginTop: 2,
            display: "grid",
            color: "black",
            width: "100%",
            marginBottom: 5,
            minHeight: "100%",
          }}
        >
          <CardHeader
            title={`Quick Navigation`}
            sx={{ marginInlineStart: 1 }}
          />
          <CardContent
            sx={{
              display: "grid",
              rowGap: 1,
              gridTemplateRows: "1fr 1fr 1fr",
              placeItems: "start",
            }}
          >
            {latestSurveyStatusData && (
              <ReminderComponent
                fundName={latestSurveyStatusData?.fund_name}
                surveyName={latestSurveyStatusData?.survey_name}
                status={latestSurveyStatusData?.survey_status}
                surveyId={latestSurveyStatusData?.survey_id}
                completionDate={latestSurveyStatusData?.updatedAt}
                type={latestSurveyStatusData?.type}
              />
            )}
            {collectSurveys && (
              <QuickAction
                title={`View Surveys`}
                handleClick={() => router.push(ROUTES.SURVEY_LIST)}
                disabled={false}
              />
            )}
            {internalEngagementInsights && (
              <QuickAction
                title={`View Engagement Analytics`}
                handleClick={() =>
                  router.push(
                    `${ROUTES.PORTFOLIO_ANALYTICS}/${internalEngagementInsights.type}/${internalEngagementInsights.instance_id}`
                  )
                }
                disabled={false}
              />
            )}
            {employeeOverview && (
              <QuickAction
                title={`View Employee Overview Table`}
                handleClick={() => router.push(ROUTES.EMPLOYEE_OVERVIEW)}
                disabled={false}
              />
            )}
            {(internalAnalytics || internalCompanyAnalytics) && (
              <QuickAction
                title={`View Internal Analytics`}
                handleClick={() => router.push(ROUTES.INTERNAL_ANALYTICS)}
                disabled={false}
              />
            )}
            {benchmarks && (
              <QuickAction
                title={`View Benchmarks`}
                handleClick={() => router.push(ROUTES.BENCHMAKRS)}
                disabled={false}
              />
            )}
          </CardContent>
        </Card>
        {features?.SHOW_HELPFUL_LINKS ? (
          <HelpfulLinksBox />
        ) : (
          <OfferBox companyDetails={companyDetails} />
        )}
      </Box>
    </Box>
  );
};

export default PortfolioHome;
